/* ./src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Helvetica Neue';
    src: url(../src/fonts/otf/HelveticaNeueLTW1G-Lt.otf);
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url(../src/fonts/ttf/HelveticaNeueLTW1G-Bd.ttf);
    font-weight: 800;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url(../src/fonts/ttf/HelveticaNeueLTW1G-Blk.ttf);
    font-weight: 900;
}


@font-face {
    font-family: 'Helvetica Neue';
    src: url(../src/fonts/ttf/HelveticaNeueLTW1G-Md.ttf);
    font-weight: 600;
}

body {
    margin: 0;
    font-family: 'Helvetica Neue', Roboto Condensed, sans-serif, 'PingFang SC';;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3C3C3C;
}

.fw600 {
    font-family: 'Helvetica Neue', Roboto Condensed, sans-serif, 'PingFang SC';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.plate-header,
.plate-footer {
    width: 208px;
}

.plate-container {
    background-size: cover;
    background-repeat: no-repeat;
}

.plate-container:focus-visible {
    outline: none;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.loader {
    color: #ffffff;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.top-39 {
    top: 39px;
}

.top-46 {
    top: 46px;
}

.top-77 {
    top: 77px;
}

.top-91 {
    top: 91px;
}

.not-selectable {
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

th{
    font-weight: 600;
}


/****** ULTRA EMPOTRAR *******/

.plates-1 .plate-container.ultra.embedded {
    background-image: url('./images/ultra-empotrar/marco-contenido-1.png');
}

.plates-2 .plate-container.ultra.embedded:nth-child(1) {
    background-image: url('./images/ultra-empotrar/marco-contenido-2a.png');
}

.plates-2 .plate-container.ultra.embedded:nth-child(2) {
    background-image: url('./images/ultra-empotrar/marco-contenido-2b.png');
}

.plates-3 .plate-container.ultra.embedded:nth-child(1) {
    background-image: url('./images/ultra-empotrar/marco-contenido-3a.png');
}

.plates-3 .plate-container.ultra.embedded:nth-child(2) {
    background-image: url('./images/ultra-empotrar/marco-contenido-3b.png');
}

.plates-3 .plate-container.ultra.embedded:nth-child(3) {
    background-image: url('./images/ultra-empotrar/marco-contenido-3c.png');
}

.plates-4 .plate-container.ultra.embedded:nth-child(1) {
    background-image: url('./images/ultra-empotrar/marco-contenido-4a.png');
}

.plates-4 .plate-container.ultra.embedded:nth-child(2) {
    background-image: url('./images/ultra-empotrar/marco-contenido-4b.png');
}

.plates-4 .plate-container.ultra.embedded:nth-child(3) {
    background-image: url('./images/ultra-empotrar/marco-contenido-4c.png');
}

.plates-4 .plate-container.ultra.embedded:nth-child(4) {
    background-image: url('./images/ultra-empotrar/marco-contenido-4d.png');
}


/****** ULTRA SUPERFICIE *******/

.plates-1 .plate-container.ultra.surface {
    background-image: url('./images/ultra-superficie/marco-contenido-1.png');
}

.plates-2 .plate-container.ultra.surface:nth-child(1) {
    background-image: url('./images/ultra-superficie/marco-contenido-2a.png');
}

.plates-2 .plate-container.ultra.surface:nth-child(2) {
    background-image: url('./images/ultra-superficie/marco-contenido-2b.png');
}

.plates-3 .plate-container.ultra.surface:nth-child(1) {
    background-image: url('./images/ultra-superficie/marco-contenido-3a.png');
}

.plates-3 .plate-container.ultra.surface:nth-child(2) {
    background-image: url('./images/ultra-superficie/marco-contenido-3b.png');
}

.plates-3 .plate-container.ultra.surface:nth-child(3) {
    background-image: url('./images/ultra-superficie/marco-contenido-3c.png');
}

.plates-4 .plate-container.ultra.surface:nth-child(1) {
    background-image: url('./images/ultra-superficie/marco-contenido-4a.png');
}

.plates-4 .plate-container.ultra.surface:nth-child(2) {
    background-image: url('./images/ultra-superficie/marco-contenido-4b.png');
}

.plates-4 .plate-container.ultra.surface:nth-child(3) {
    background-image: url('./images/ultra-superficie/marco-contenido-4c.png');
}

.plates-4 .plate-container.ultra.surface:nth-child(4) {
    background-image: url('./images/ultra-superficie/marco-contenido-4d.png');
}


/****** ULTRA VANDALCOM *******/

.plates-1 .plate-container.vandalcom {
    background-image: url('./images/vandalcom/marco-contenido-1.png');
}

.plates-2.vandalcom,
.plates-3.vandalcom {
    width: 208px;
}

.plates-2 .plate-container.vandalcom:nth-child(1) {
    background-image: url('./images/vandalcom/marco-contenido-2a.png');
}

.plates-2 .plate-container.vandalcom:nth-child(3) {
    background-image: url('./images/vandalcom/marco-contenido-2b.png');
}

.plates-3 .plate-container.vandalcom:nth-child(1) {
    background-image: url('./images/vandalcom/marco-contenido-3a.png');
}

.plates-3 .plate-container.vandalcom:nth-child(3) {
    background-image: url('./images/vandalcom/marco-contenido-3b.png');
}

.plates-3 .plate-container.vandalcom:nth-child(5) {
    background-image: url('./images/vandalcom/marco-contenido-3c.png');
}


/* 
.plates-4 .plate-container.vandalcom:nth-child(1) {
    background-image: url('./images/vandalcom/marco-contenido-4a.png');
}

.plates-4 .plate-container.vandalcom:nth-child(3) {
    background-image: url('./images/vandalcom/marco-contenido-4b.png');
}

.plates-4 .plate-container.vandalcom:nth-child(5) {
    background-image: url('./images/vandalcom/marco-contenido-4c.png');
}

.plates-4 .plate-container.vandalcom:nth-child(7) {
    background-image: url('./images/vandalcom/marco-contenido-4d.png');
} */