/** Utilidades **/

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.App {
    min-height: 100vh;
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.App-header {
    background-color: #F3F3F3;
}

.App-header .logo {
    max-width: 120px;
    padding: 1.5rem 0;
}

.App-footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 0;
    color: white;
    font-size: 14px;
    line-height: 17px;
    background-color: #707070;
}

.App-footer .social {
    max-width: 200px;
}

.App-footer .social a {
    height: 2rem;
    width: 2rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
}

.App-footer .social a i {
    color: #707070;
}

.contentApp,
.contentApp .home {
    min-height: calc(100vh - 320px);
}

.contentApp .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-btn {
    max-width: 16rem;
}

input[type="radio"] {
    margin-top: -4px;
    vertical-align: middle;
}

button:focus,
:focus-visible {
    outline: none;
}

.bg-granite {
    background-image: url(./images/granite-wall.jpeg);
    background-size: cover
}

.bg-brick {
    background-image: url(./images/brick-wall.jpeg);
    background-size: cover
}

.css-2613qy-menu {
    z-index: 30 !important;
}


/* Language selector */

.lang {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    left: 0;
    display: none;
    margin: 0;
    padding: 0;
    padding-top: 30px;
    list-style: none;
}

.lang:hover .dropdown,
.dropdown:hover {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown li {
    margin-top: 10px;
}


/*
.lang:hover li{
    margin: 0;
    display: block;
    padding: 5px 0;
} */

.flag {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: .2s ease-in;
    border-radius: 10px;
    background-color: transparent;
}

.flag:hover {
    transform: scale(1.1);
}


/* https://iconos8.es/icon/set/flags/color */

.es {
    background-image: url("./flags/es.svg");
}

.pt {
    background-image: url("./flags/pt.svg");
}

.en {
    background-image: url("./flags/en.png");
    background-position: center;
}

.se {
    background-image: url("./flags/se.svg");
}